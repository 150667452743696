import React, { useState } from 'react';
import { LuPlusSquare } from "react-icons/lu";
import { PiMinusSquareLight } from "react-icons/pi";

function SearchBox({ name }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div style={{
      maxWidth: '300px',
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
      padding: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      transition: 'box-shadow 0.2s ease',
      fontFamily: 'Arial, sans-serif'
    }}>

      {/** Button to expand the search option */}
      <div
        onClick={() => setIsExpanded(!isExpanded)}
        style={{
          gap: '10px',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          color: '#333',
          padding: '5px 0',
          transition: 'color 0.2s ease'
        }}
      >
        {isExpanded ? <PiMinusSquareLight size={20} color="gray" /> : <LuPlusSquare size={20} color="gray" />}
        <span style={{ fontSize: '12px' }}>{name}</span>
      </div>

      {/** Expanded block to enter query and search */}
      {isExpanded && (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '10px',
          gap: '10px',
          alignItems: 'stretch'
        }}>
          <input
            type="text"
            placeholder={`Enter ${name}`}
            style={{
              padding: '8px 12px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              outline: 'none',
              fontSize: '14px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              transition: 'box-shadow 0.2s ease, border-color 0.2s ease'
            }}
            onFocus={(e) => {
              e.target.style.borderColor = '#007BFF';
              e.target.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.15)';
            }}
            onBlur={(e) => {
              e.target.style.borderColor = '#ccc';
              e.target.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.1)';
            }}
          />
          
          <button
            style={{
              padding: '10px 15px',
              backgroundColor: 'gray',
              border: 'none',
              borderRadius: '5px',
              color: '#fff',
              cursor: 'pointer',
              transition: 'background-color 0.2s ease, box-shadow 0.2s ease',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              fontSize: '14px'
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = 'lightblue')}
            onMouseLeave={(e) => (e.target.style.backgroundColor = 'gray')}
          >
            Add
          </button>
        </div>
      )}
    </div>
  );
}

export default SearchBox;
