import { connectionStrategies, dia, g, linkTools, shapes, util } from '@joint/core';
import { useLayoutEffect, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import rough from 'roughjs';
import svgPanZoom from 'svg-pan-zoom';

import ElementModel from './formComponents/ElementModel';
import Header from './Header';
import Controls from './stylePaletteComponents/Controls';
import Slider from './query/Slider';

import AddLabel from './utils/AddLabel';
import AddDoubleClickTools from './utils/AddTools';
import AOPelements from './utils/Elements';
import exportAsPNGorJPEG from './utils/ExportImage';
import { RoughLink, UpdateLinkEndpoints } from './utils/Links';
import { exportGraphAsJSON, importGraphFromJSON } from './utils/SaveGraph';
import FetchTemplate from './formComponents/utils/fetchTemplate.js';
import { clearFormData, storeFormData } from '../redux/slices/form/form';
import { set } from 'lodash';

const Paper = () => {

  /**
     * Note: We are defining custom elements here, to allow the functionality of loading JSON into the graph
     * We need to store the constructor of the shapes and Link, then all the attributes will passed from json to
     * the constructor.
     * https://docs.jointjs.com/learn/features/shapes/cell-namespaces/
     * https://docs.jointjs.com/learn/features/shapes/custom-shapes/

    * Register elements in shapes group
    * https://docs.jointjs.com/learn/features/shapes/custom-shapes/
    * https://docs.jointjs.com/learn/features/shapes/cell-namespaces/
  */

  const [isPublish, setIsPublish] = useState(false);
  const [isModalVisible, setModalVisible] = useState(()=>{return {visibility:false,text:''}});
  const [elementType, setElementType] = useState(null);
  const [graph, setGraph] = useState('');

  /**paper and papermodel ref */
  const paperRef = useRef(null);
  const paperGraph = useRef(null);

  /**style refs */
  const strokeRef = useRef("#E5E4E2");
  const borderRef = useRef("#000000");
  const fillRef = useRef("#ffffff");
  const styleRef = useRef('solid');
  const roughRef = useRef([0.5]);
  const bgRef = useRef();

  /** tool refs */
  const selectedToolRef = useRef(null);
  const selectedFontRef = useRef('Arial');
  const textEditorRef = useRef(null);
  const panZoomRef = useRef(null);
  const elementRef = useRef(null);

  /** Redux */
  const dispatch = useDispatch();
  const formData = useSelector(state => state.form.data);

  const renderGraph = (url) => {
    FetchTemplate(url).then(data => {
      const graphData = data[1]["@graph"][0]["https://w3id.org/np/RALUzHtS1E_Mba_zwMhhENjZgd23vM0IivF-tUBC633dI#hasGraph"][0]["@value"];
      const graphObj = JSON.parse(graphData);
      const currentGraph = paperGraph.current.model.toJSON();
      const mergedGraph = {
        ...currentGraph,
        cells: [...currentGraph.cells, ...graphObj.cells]
      };
      paperGraph.current.model.fromJSON(mergedGraph);
    })
  };

  const createMIE = (item) => {
    selectedToolRef.current = item;
    const mieShape = AOPelements.create(
      selectedFontRef,
      panZoomRef.current,
      selectedToolRef,
      strokeRef,
      fillRef,
      roughRef,
      borderRef,
      styleRef
    );
    mieShape.position(50, window.innerHeight-200);
    mieShape.resize(150, 150);
    paperGraph.current.model.addCell(mieShape);
    selectedToolRef.current = '';
  };
  

  const handleExport = (format,bg) => {
    if (paperGraph.current) {
      removeAllTools(paperGraph.current, paperGraph.current.model);
      exportAsPNGorJPEG(paperGraph.current, format,bg);
    }
  };

  const handleSave = () => {
    if (paperGraph.current) {
      exportGraphAsJSON(paperGraph); /** Export of graph as json */
    }
  };

  const handleClearData = () => {
    dispatch(clearFormData());
  };

  const removeAllTools = (paper, graph) => {
    const cells = graph.getElements();
    cells.forEach(cell => {
      const view = paper.findViewByModel(cell);
      if (view) {
        view.removeTools();
      }
    });
  };

  const filterGraph = (data) => {
    return {
      ...data,
      cells: data.cells.filter(cell => {
        if (cell.type === "AOPelements") {
          return ["MIE", "KE", "AOP", "AO"].includes(cell.elementtype);
        }
        return true; // Keep all other types
      })
    };
  };

  useLayoutEffect(() => {

    var customNamespace = {
      ...shapes,
      AOPelements, 
      RoughLink
    };

    /*  initializing the graph  */
    const graph = new dia.Graph({},{
      cellNamespace: customNamespace,
    });

    /*   Initializing the paper   */
    const paper = new dia.Paper({
      el: paperRef.current,
      height: "100%", // Pass whole screen as svg area for drawing
      width: "100%",
      gridSize: 1,
      model: graph,
      cellViewNamespace: customNamespace,
      clickThreshold: 5,
      async: true,
      connectionStrategy: connectionStrategies.pinAbsolute,  //multiple links between same elements
      defaultLink: ()=> {
        return new RoughLink({
          attrs: {
              line: {
                  'stroke-dasharray': selectedToolRef.current === 'dashedLink' ? '8' : '0',
              }
          }
      });
      },
      validateMagnet: function (_view, magnet) {
        panZoomInstance.disablePan();
        return magnet.getAttribute('magnet') === 'on-shift' && (selectedToolRef.current==='dashedLink' || selectedToolRef.current==='solidLink'); //makes sure there are no links from nothing
      }
  });

  /*  making paper rough */
  const Rough = rough.svg(paper.svg);
  const borderEl = Rough.rectangle(0, 0,
                        window.innerWidth,
                        window.innerHeight,
                      {
                        stroke: 'transparent',
                    });
  paper.svg.appendChild(borderEl);
  paper.rough = Rough;

  /*  Integrating svg-pan-zoom with paper   */
  const panZoomInstance = svgPanZoom(Rough.svg, {
    dblClickZoomEnabled: false,
    minZoom: 0.1,
    maxZoom: 5,
    onUpdatedCTM: function(matrix) {
      const { a, d, e, f } = matrix;
      const { a: ca, d: cd, e: ce, f: cf } = panZoomInstance.getZoom();
      const translateChanged = e !== ce || f !== cf;
      if (translateChanged) {
        paper.trigger('translate', e - ce, f - cf);
      }
      const scaleChanged = a !== ca || d !== cd;
      if (scaleChanged) {
        paper.trigger('scale', a, d, e, f);
      }
    }
  });

  panZoomRef.current = panZoomInstance;

  /** paper ref */
  paperGraph.current = paper;

  /**
   * 
   * Function to make paper responsive
   */
  function scaleContentToFit() {
  paper.transformToFitContent({padding: 20, minScaleX: 0.3, minScaleY: 0.3, maxScaleX: 1 , maxScaleY: 1});
  }

  window.addEventListener('resize', util.debounce(scaleContentToFit), false);
  scaleContentToFit();

  /*
    // repositioning the controlIcons of svgpanzoom
    const controls = document.querySelector('g[id="svg-pan-zoom-controls"]');
    const svgElement = document.querySelector('svg');
    const svgHeight = svgElement.getBoundingClientRect().height;
    controls.setAttribute('transform', 'translate(10, ' + (svgHeight - 94) + ')');

    // Adjust the size of the control icons
    const controlButtons = controls.querySelectorAll('circle, path');
    controlButtons.forEach(button => {
      button.setAttribute('transform', 'scale(0.8)');
    });
  */

    graph.on({'change:size': (element) => {
      UpdateLinkEndpoints(element, graph);
      },
      /** to prevent element-overlap */
      // 'change:position': (cell) => {
      //     const cellView = paper.findViewByModel(cell);
      //     if (CheckCollision(cellView, graph, paper)) {
      //       cell.set('position', cell.previous('position'));
      //     }
      //   },
    });

    document.addEventListener('keydown', (event) => {
      if (event.key === "Escape") {
          if (textEditorRef.current && textEditorRef.current.style.display === 'block') {
              textEditorRef.current.blur();
          }
          removeAllTools(paper, graph);
      }
  });

    paper.on({
      'link:mouseenter': function (linkView) {
        linkView.addTools(new dia.ToolsView({
          tools: [
            new linkTools.Vertices({ snapRadius: 0 }),
            new linkTools.TargetArrowhead(),
            new linkTools.Remove({
              distance: 20
            })
          ]
        }));
      },
      'link:mouseleave': function (linkView) {
        linkView.removeTools();
      },
      'element:pointerclick': function (elementView) {
        console.log(elementView.model["id"]);
        elementRef.current = elementView.model;
      },
      'element:pointerdblclick': function (elementView) {
        AddDoubleClickTools(elementView, textEditorRef, setModalVisible, setElementType, dispatch, setIsPublish);
        AddLabel(elementView, textEditorRef);

        setModalVisible(prevState => ({
          ...prevState,
          visibility: false,
          text: prevState.textLabel
        }));
      },
      'element:pointerdown': function () {
        if (selectedToolRef.current!=='select') {
          panZoomInstance.disablePan();
        }
        else{panZoomInstance.enablePan();}
        if (textEditorRef.current.style.display === 'block') textEditorRef.current.blur();
      },
      'blank:pointerdown': function(evt, x, y) {
        if (selectedToolRef.current !=="select"){
          // panZoomInstance.disablePan();
          var data = evt.data = {};
          var cell;
          cell = AOPelements.create(selectedFontRef,panZoomInstance, selectedToolRef, strokeRef, fillRef, roughRef, borderRef, styleRef)
          cell.position(x, y);
          data.x = x;
          data.y = y;
          cell.addTo(this.model);
          data.cell = cell;
        }else{
          panZoomInstance.enablePan();
        }
        textEditorRef.current.blur();
      },
      'blank:pointerclick': function() {
          setModalVisible(false);
      },
      'blank:pointermove': function(evt, x, y) {
        if (selectedToolRef.current !=="select" && selectedToolRef.current !== ''){
          panZoomInstance.disablePan()
          var data = evt.data;
          var cell = data.cell;
          var bbox = new g.Rect(data.x, data.y, x - data.x, y - data.y);
          bbox.normalize();
          if (selectedToolRef.current === 'KE' || selectedToolRef.current === 'MIE' || selectedToolRef.current === 'AO' || selectedToolRef.current === 'AOP'){
          cell.set({
            position: { x: bbox.x, y: bbox.y },
            size: { width: Math.max(bbox.width, 1), height: Math.max(bbox.height, 1) }
          });
        }
      }
      },
      'blank:pointerup': function(evt) {
        if (selectedToolRef.current !== "select") { // select is pan
          if (selectedToolRef.current === 'KE' || selectedToolRef.current === 'MIE' || selectedToolRef.current === 'AO' || selectedToolRef.current === 'AOP'){
          const data = evt.data;
          const cell = data.cell;
          const size = cell.size();
          if (size.width < 20 || size.height < 20) {
            cell.remove();

          }}
        }
          graph.getLinks().forEach(link => {
          const source = link.getSourceElement();
          const target = link.getTargetElement();
          if (!source || !target) {
            link.remove();
          }
        });
      },
      'link:pointerup': function(linkView) {
        const link = linkView.model;
        const source = link.getSourceElement();
        const target = link.getTargetElement();
        if (!source || !target || source === target) {
          link.remove();
        }
        selectedToolRef.current = '';
      },
    });

    const graphObj = JSON.parse(localStorage.getItem('renderGraph'));
    if (graphObj) {
      paperGraph.current.model.fromJSON(graphObj);
    }

    const savedFormData = JSON.parse(localStorage.getItem('formdata'));

    if (savedFormData && savedFormData.length > 0) {
        dispatch(clearFormData()); // Clear existing data first
        savedFormData.forEach((item) => {
            dispatch(storeFormData(JSON.stringify(item)));
        });
    }
}, [dispatch]);

  // Save data on beforeunload
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (textEditorRef.current && textEditorRef.current.style.display === 'block') {
        textEditorRef.current.blur();
      }

      const graphData = paperGraph.current.model.toJSON();
      localStorage.setItem('renderGraph', JSON.stringify(graphData));
      localStorage.setItem('formdata', JSON.stringify(formData)); // Directly using formData
    };

    // Register the beforeunload event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [formData]);

  return (
  <div
  style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
  }}>

  {/* 
  //  dynamic styling example

  <button onClick={() => {
    elementRef.current.attr({
      pointers: {
          fill: 'yellow',
        },
      }); 
    }}>rough
  </button>
    */}
 
    <div
      id='paper'
      ref={paperRef}
      style={{
        width: 'auto',
        height: 'auto',
        background: "#EDFEFF",
        overflow:'auto'
      }}
    />

    <Header
      onFont={(font) => {
        selectedFontRef.current = font;
      }}
      onStyle={(style) => {
        styleRef.current = style;
      }}
      onStroke={(stroke) => {
        strokeRef.current = stroke
        fillRef.current = '#ffffff';
      }}
      onBorder={(border) => {
        borderRef.current = border
      }}
      onBackground={(bg) => {
        bgRef.current = bg; //for exporting as image
        paperRef.current.style.backgroundColor = bg;
      }}
      onFill={(fill) => {
        fillRef.current = fill
        strokeRef.current = fill === '#ffffff' ? '#E5E4E2' : '#ffffff'
      }}
      onRough={(rough) => {
        roughRef.current = rough
      }}
      onSelect={(type) =>{ 
        selectedToolRef.current = type;
        if (type === 'publish') {
          setElementType('AOP');
          setModalVisible({ visibility: true, text: 'Publish AOP' });
          setIsPublish(true);
          if(paperGraph.current) setGraph(JSON.stringify(filterGraph(paperGraph.current.model.toJSON()), null, 2));
        }
        if(type ==="save"){
          handleSave();
        }
        if (type === 'export') {
          handleExport('png', bgRef.current);
        }
        if (type === 'trash'){
          setModalVisible(false);
          handleClearData();
          setGraph('');
          paperGraph.current.model.fromJSON({"cells": []});
          paperRef.current.style.backgroundColor = '#EDFEFF';
        }}}
        
        /** File selection initiate this */
        fileSelectionEvent={(event) =>{
          importGraphFromJSON(paperGraph,event)
        }}
    />

    <div
      ref={textEditorRef}
      className="text-editor"
      contentEditable="true"
    />

    <ElementModel
      publish={isPublish}
      visible={isModalVisible.visibility}
      elementType={elementType}
      onClose={() => setModalVisible(false)}
      text={isModalVisible.text}
      objectID={isModalVisible.objectID}
      Graph={graph}
    />

    <Controls panZoom= {panZoomRef}/>

    <Slider
      renderGraph={renderGraph}
      createMIE={createMIE}
    />

  </div>
  );
}

export default Paper;