import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function ResponsiveDialog({ onConfirm, validateForm }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Handle opening dialog if validation passes
  const handleClickOpen = () => {
    if (validateForm()) {
      setOpen(true); // Open the dialog only if validation passes
    } else {
      // If form is not valid, show error message directly on the form
      // This assumes form library (like react-jsonschema-form) handles showing validation errors automatically
      toast.error('Please fill out all required fields.', { // Show toast error
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
        // transition: Bounce, // If you have a Bounce transition, you can import it as well
      });    }
  };

  // Close the dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Handle the final confirmation and form submission
  const handleAgree = () => {
    onConfirm(); // Call the submit handler
    handleClose();
  };

  return (
    <React.Fragment>
      {/* Note: Remove 'type="submit"' */}
      <Button variant="outlined" onClick={handleClickOpen}>
        Submit
      </Button>

      {/* Dialog component */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Are you sure you want to submit?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            It will publish the data as a nanopublication at{' '}
            <a
              href='https://np.knowledgepixels.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              https://np.knowledgepixels.com
            </a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Go Back</Button>
          <Button onClick={handleAgree} autoFocus>
            Publish
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer /> 
    </React.Fragment>
  );
}
