import { V, dia } from '@joint/core'

/**
 * 
 * POINTERS- MIE/POLYGONS
 * BODY- KE/CIRCLE
 * 
 */
const AOPelements = dia.Element.define('AOPelements', {
    attrs: {
      root: {
        magnet: false
      },
      pointers: {
        width: 'calc(w)',
        height: 'calc(h)',
      },
      border: {
        rough: {
          fillSketch: true
        },
        fill: 'none',   //MIE-POLYGONS
      },
      body: {
        fill: 'none',  // KE-CIRCLE
      },
      label: {
        textWrap: {
          ellipsis: true,
        },
        z: 10,
        textVerticalAnchor: 'middle',
        textAnchor: 'middle',
        border:'none',
        outline:'none',
        refX: '50%',
        refY: '50%',
        fontSize: 20,
      },
    }
  },

  // it defines the basic stucture of the shape, all the components it has
  {
    markup: [
      {
        tagName: 'path',
        selector: 'pointers',
        attributes: {
          'magnet': 'on-shift',
        }
      },{
        tagName: 'path',
        selector: 'body',
        attributes: {
          'pointer-events': 'none',
        },
      }, {
        tagName: 'path',
        selector: 'border',
        attributes: {
          'pointer-events': 'none',
        },
      }, {
        tagName: 'text',
        selector: 'label',
      }
    ],
  },
  
  {
    create: function(selectedFontRef, panZoomInstance, selectedToolRef, strokeRef, fillRef, roughRef, borderRef, styleRef) {
      const type = selectedToolRef.current;
      
      return new this({
        attrs: {
          pointers: { //MIE-POLYGONS
            pointerShape: type,
            fill: fillRef.current,  //for all 
          },
          body:{  //KE-CIRCLE
            rough:{
              type:type,
            },
            stroke: borderRef.current, //for all   //BORDER
            strokeWidth: 3,  //BORDER
          },
          label: {
            text: type+': ',
            fontFamily: selectedFontRef.current
          },
          border: {   //STROKE
            rough: {
              type: type,
            },
            stroke: strokeRef.current,
          }
        },

        elementtype: type,
        panZoomInstance: panZoomInstance,
        selectedToolRef: selectedToolRef,
        roughRef: roughRef,
        styleRef: styleRef,
      });
    },
    attributes: {
      'rough': {
        set: function(opt, bbox) {
          var r = this.paper.rough;
          if (!r) return;
          var shape;
          switch (opt.type) {
            case 'MIE':
              shape = r.generator.rectangle(bbox.x, bbox.y, bbox.width, bbox.height, {
                fill: true,
                roughness:  this.model.attributes.roughRef.current,
                hachureGap: 13,
                fillWeight: 4,
                zigzagOffset : 9,
                fillStyle: this.model.attributes.styleRef.current,
              });
              break;
              case 'KE':
                const centerX = bbox.x + bbox.width / 2;
                const centerY = bbox.y + bbox.height / 2;
                const radius = Math.min(bbox.width / 2, bbox.height / 2);
                // const circlePath = `M ${centerX - radius} ${centerY} a ${radius} ${radius} 0 1 0 ${2 * radius} 0 a ${radius} ${radius} 0 1 0 -${2 * radius} 0`;
                shape = r.generator.circle(centerX, centerY, radius*2,{
                  fill: true,
                  roughness:  this.model.attributes.roughRef.current/2.5,
                  hachureGap: 13,
                  fillWeight: 4,
                  zigzagOffset : 9,
                  fillStyle: this.model.attributes.styleRef.current,
                });
              break;
            case 'AOP':
              shape = r.generator.polygon([ //don't use polygon si es quieres edges rounded
                [bbox.x, bbox.y + bbox.height],
                [bbox.x + bbox.width / 2, bbox.y],
                [bbox.x + bbox.width, bbox.y + bbox.height]
              ], {
                fill: true,
                roughness:  this.model.attributes.roughRef.current,
                hachureGap: 13,
                fillWeight: 4,
                zigzagOffset : 9,
                fillStyle: this.model.attributes.styleRef.current,
              });
              break;
            case 'AO':
              shape = r.generator.polygon([
                [bbox.x, bbox.y + bbox.height / 2],
                [bbox.x + bbox.width / 2, bbox.y],
                [bbox.x + bbox.width, bbox.y + bbox.height / 2],
                [bbox.x + bbox.width / 2, bbox.y + bbox.height]
              ], {
                fill: true,
                roughness:  this.model.attributes.roughRef.current,
                hachureGap: 13,
                fillWeight: 4,
                zigzagOffset : 9,
                fillStyle: this.model.attributes.styleRef.current,
              });
              break;
            default:
              return;
          }
          if (shape && shape.sets) {
            var sets = shape.sets;
            return { d: r.opsToPath(sets[opt.fillSketch ? 0 : 1]) };
          }
        }
      },
      'pointer-shape': {
        set: function(type,bbox) {
          var vel;
          var width = bbox.width;
          var height = bbox.height;
          switch (type) {
            case 'MIE':
              vel = V('rect').attr({
                'width': width,
                'height': height,
              });
              break;
            case 'KE':
              vel = V('circle').attr({
                'cx': width / 2,
                'cy': height / 2,
                'r': Math.min(width, height) / 2,
              });
              break;
            case 'AOP':
              vel = V('polygon').attr({
                'points':`${bbox.x},
                          ${bbox.y + bbox.height} ${bbox.x + bbox.width / 2},
                          ${bbox.y} ${bbox.x + bbox.width},
                          ${bbox.y + bbox.height}`,
              });
              break;
            case 'AO':
              vel = V('polygon').attr({
                'points':`${bbox.x + bbox.width / 2},
                          ${bbox.y} ${bbox.x + bbox.width},
                          ${bbox.y + bbox.height / 2} ${bbox.x + bbox.width / 2},
                          ${bbox.y + bbox.height} ${bbox.x},
                          ${bbox.y + bbox.height / 2}`,
              });
              break;
            default:
              // this.model.attributes.panZoomInstance.disablePan();
              this.model.attributes.selectedToolRef.current='';
              return;
          }
          return { d: vel.convertToPathData() };
        }
      }
    }
  });

export default AOPelements;