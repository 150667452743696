function GenerateSchema(inputSchema, aopTitle) {

  const outputSchema = {
    title: aopTitle,
    type: "object",
    required: [],
    properties: {}
  };

  const uiSchema = {};

  const statementTemplatesMap = inputSchema.statementTemplates.reduce((map, template) => {
    map[template.id] = template;
    return map;
  }, {});

  const processStatement = (statementId, parentUiSchema = uiSchema) => {
    const template = statementTemplatesMap[statementId];
    if (!template) return null;

    const propertyName = template.object;
    const title = propertyName.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');

    let fieldSchema = {
      title: title,
      type: "string"
    };

    if (template.type.includes("RepeatableStatement") && template.type.includes("GroupedStatement")) {
      fieldSchema = {
        type: "array",
        title: title,
        items: {
          type: "object",
          properties: {}
        },
        minItems: 1,
        uniqueItems: true
      };
      parentUiSchema[propertyName] = { items: { "ui:emptyValue": null } };
      template.subStatements.forEach(subStatementId => {
        const subFieldSchema = processStatement(subStatementId, parentUiSchema[propertyName].items);
        if (subFieldSchema) {
          fieldSchema.items.properties[subFieldSchema.propertyName] = subFieldSchema.schema;
        }
      });
    } else if (template.type.includes("RepeatableStatement")) {
      fieldSchema = {
        type: "array",
        title: title,
        items: {
          type: "string",
          title: title
        },
        minItems: 1,
        uniqueItems: true
      };
      parentUiSchema[propertyName] = { items: { "ui:emptyValue": null } };
    } else if (template.type.includes("GroupedStatement")) {
      fieldSchema = {
        type: "object",
        title: title,
        properties: {}
      };
      parentUiSchema[propertyName] = { "ui:emptyValue": null };
      template.subStatements.forEach(subStatementId => {
        const subFieldSchema = processStatement(subStatementId, parentUiSchema[propertyName]);
        if (subFieldSchema) {
          fieldSchema.properties[subFieldSchema.propertyName] = subFieldSchema.schema;
        }
      });
    } else if (template.type.includes("RestrictedChoice")) {
      fieldSchema = {
        type: "string",
        title: title,
        enum: template.possibleValues
      };
    }

    // Update uiSchema
    if (template.dataType.includes("Literal")) {
      if (template.type.includes("RepeatableStatement")) {
        parentUiSchema[propertyName] = {
          items: {
            "ui:widget": "text",
            "ui:emptyValue": null
          }
        };
      } else {
        parentUiSchema[propertyName] = {
          "ui:widget": "text",
          "ui:emptyValue": null
        };
      }
    } else if (template.dataType.includes("LongLiteral")) {
      if (template.type.includes("RepeatableStatement")) {
        parentUiSchema[propertyName] = {
          items: {
            "ui:widget": "textarea",
            "ui:emptyValue": null
          }
        };
      } else {
        parentUiSchema[propertyName] = {
          "ui:widget": "textarea",
          "ui:emptyValue": null
        };
      }
    }

    if (template.type.includes("Statement")) {
      return {
        propertyName: propertyName,
        schema: fieldSchema,
        required: true
      };
    } else {
      return {
        propertyName: propertyName,
        schema: fieldSchema
      };
    }
  };

  const validStatementPattern = /^st-?\d+$/;

  inputSchema.Statements.forEach(statementId => {
    if (validStatementPattern.test(statementId)) {
      const result = processStatement(statementId);
      if (result) {
        outputSchema.properties[result.propertyName] = result.schema;
        if (result.required) {
          outputSchema.required.push(result.propertyName);
        }
      }
    }
  });

  return { outputSchema, uiSchema };
}

export default GenerateSchema;
