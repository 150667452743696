import React, { useState, useRef, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import { BsFillArrowLeftSquareFill } from "react-icons/bs";
import { RxHamburgerMenu } from "react-icons/rx";
import AOPSearch from './AOPSearch';
import KESearch from './KESearch';

function Slider({ renderGraph, createMIE }) {

  const [isOpen, setIsOpen] = useState(false); // Manage sidebar visibility
  const [displayOption, setDisplayOption] = useState("AOP"); // Switch between AOP and KE
  const tooltipRef = useRef(null);

  // Close sidebar when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const SlideStyle = {
    backgroundColor: 'transparent',
    borderRadius: '50%',
    position: 'absolute',
    top: 20,
    right: 20,
    padding: '5px',
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
  };

  const TooltipContainer = {
    position: 'absolute',
    top: '60px',
    right: '20px',
    width: '320px',
    padding: '10px',
    maxHeight: '600px',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
    overflowY: 'auto',
    zIndex: 1000,
  };

  return (
    <Col>

      {/** Button to open/close the sidebar search */}
      <Row
        style={SlideStyle}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen
          ? <RxHamburgerMenu size={24} color="#007BFF" />
          : <BsFillArrowLeftSquareFill size={24} color="#333" />}
      </Row>

      {isOpen && (

        <Row ref={tooltipRef} style={TooltipContainer}>

          <Row style={{ justifyContent: 'center', gap: '5px', marginBottom: '5px' }}>
            <Col
              style={{
                color: "#333",
                border: '1px solid #ddd',
                borderRadius: '6px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '5px',
                backgroundColor: displayOption === "AOP" ? 'Lightgray' : 'white',
                cursor: 'pointer',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
              onClick={() => setDisplayOption("AOP")}
            >
              <span>AOP</span>
            </Col>

            <Col
              style={{
                color: "#333",
                border: '1px solid #ddd',
                borderRadius: '6px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px',
                cursor: 'pointer',
                backgroundColor: displayOption === "KE" ? 'Lightgray' : 'white',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
              onClick={() => setDisplayOption("KE")}
            >
              <span>Events</span>
            </Col>
          </Row>

          <Row style={{ gap: '10px', flexDirection: 'column' }}>
            {displayOption === "AOP" ? <AOPSearch renderGraph={renderGraph} /> : <KESearch createMIE={createMIE} />}
          </Row>

        </Row>
      )}
    </Col>
  );
}

export default Slider;
