
const backend_host = process.env.REACT_APP_BACKEND_URL
export async function SendDataToBackend(dataToSend) {
  const token = localStorage.getItem('token');
  
  const response = await fetch(`${backend_host}/api/v1/data`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(dataToSend),
  });

  const result = await response.json();
  console.log(result.message); // Should print "Data received successfully"
};

export default SendDataToBackend;
