const STATEMENTREGEX = /\bst\S*\b/;
const HASHREGEX = /#([^#]*)$/;
const SLASHREGEX = /\/([^/]+)$/;
const HASHSLASHREGEX = /[#\/]([^#\/]+)$/;
const RDFOBJECT = "http://www.w3.org/1999/02/22-rdf-syntax-ns#object";
const RDFSUBJECT = "http://www.w3.org/1999/02/22-rdf-syntax-ns#subject";
const RDFPREDICATE = "http://www.w3.org/1999/02/22-rdf-syntax-ns#predicate";


/**
 * extractTemplateSchema takes a templateData object and returns an object with
 * two properties: statementTemplates and Statements. statementTemplates is an
 * array of objects, each representing a statement in the template. Each
 * statement object has the following properties:
 *   - id: the id of the statement
 *   - subject: the id of the subject of the statement
 *   - predicate: the id of the predicate of the statement
 *   - object: the id of the object of the statement
 *   - type: an array of types of the statement, e.g. RepeatableStatement
 *   - dataType: an array of data types of the statement, e.g. Literal
 *   - possibleValues: an array of possible values of the statement, e.g. a list
 *     of options for a dropdown
 *   - subStatements: an array of ids of sub-statements of the statement
 *
 * Statements is an array of ids of statements in the template.
 *
 * @param {object} templateData The template data to extract the schema from.
 * @returns {object} An object with two properties: statementTemplates and
 * Statements.
 */
export default function extractTemplateSchema(templateData) {
    const Statements = [];
    const statementTemplates = [];

    // Initial pass to create statementTemplates
    templateData.map((node) => {
        if (node["@id"].match(STATEMENTREGEX)) {
            if (
                Object.keys(node).includes(RDFOBJECT) &&
                Object.keys(node).includes(RDFSUBJECT) &&
                Object.keys(node).includes(RDFPREDICATE)
            ) {
                const Type = node["@type"] && node["@type"].map((type) => {
                    const match = type.match(HASHSLASHREGEX);
                    return match ? match[1] : null;
                });

                const statementTemp = {
                    id: node["@id"].match(HASHREGEX)[1],
                    subject: node[RDFSUBJECT][0]["@id"].match(HASHREGEX)[1],
                    predicate: node[RDFPREDICATE][0]["@id"].match(HASHREGEX)[1],
                    object: node[RDFOBJECT][0]["@id"].match(HASHREGEX)[1],
                    type: Type && Type.length > 0 ? Type : [],
                    dataType: [],
                    possibleValues: [],
                    subStatements: []
                };

                if (node["https://w3id.org/np/o/ntemplate/hasStatement"]) {
                    statementTemp.subStatements = node["https://w3id.org/np/o/ntemplate/hasStatement"].map(statement => {
                        return statement["@id"].match(HASHREGEX)[1];
                    });
                }

                statementTemplates.push(statementTemp);
            }
        } else if (node["@id"].endsWith("assertion")) {
            if (node["https://w3id.org/np/o/ntemplate/hasStatement"]) {
                node["https://w3id.org/np/o/ntemplate/hasStatement"].forEach(statement => {
                    const statementId = statement["@id"].match(HASHREGEX)[1];
                    Statements.push(statementId);
                });
            }
        }
    });

    // Second pass to update types, dataType, and possible values
    templateData.map((node) => {
        if (!node["@id"].match(STATEMENTREGEX) && !node["@id"].endsWith("assertion")) {
            const Type = node["@type"] && node["@type"].map((type) => {
                const match = type.match(HASHSLASHREGEX);
                return match ? match[1] : null;
            });

            const possibleValues = node["https://w3id.org/np/o/ntemplate/possibleValue"] ? 
                node["https://w3id.org/np/o/ntemplate/possibleValue"].map(value => {
                    return value["@id"].match(HASHREGEX)[1];
                }) : [];

            const objectId = node["@id"].match(HASHREGEX)[1];
            const existingTemplate = statementTemplates.find(template => template.object === objectId);

            if (existingTemplate) {
                if (Type) {
                    if (Type.includes("RestrictedChoicePlaceholder")) {
                        existingTemplate.type.push("RestrictedChoice");
                    }
                    if (Type.includes("LiteralPlaceholder")) {
                        existingTemplate.dataType.push("Literal");
                    }
                    if (Type.includes("LongLiteralPlaceholder")) {
                        existingTemplate.dataType.push("LongLiteral");
                    }
                }
                if (possibleValues.length > 0) {
                    existingTemplate.possibleValues = possibleValues;
                }
            }
        }
    });

    return {
        statementTemplates,
        Statements
    };
}
