import React, { useRef, useState } from 'react';
import { BsDashLg } from "react-icons/bs";
import { Container, Row, Col} from 'react-bootstrap';
import {
    FaCircle,
    FaLocationArrow, FaSquare, FaTrash
} from "react-icons/fa";
import { FaDiamond, FaFont } from "react-icons/fa6";
import { IoHandLeft, IoTriangle } from "react-icons/io5";
import { RxBorderDashed } from "react-icons/rx";
import { SlActionRedo, SlActionUndo } from "react-icons/sl";
import DesignPalette from './stylePaletteComponents/DesignPalette';
import Background from './stylePaletteComponents/Background';
import Menu from './stylePaletteComponents/Menu';
import Fonts from './stylePaletteComponents/Fonts';

import '../Header.css';

function Header({  
                onSelect,
                fileSelectionEvent,
                onFont,
                onStroke,
                onRough,
                onStyle,
                onFill,
                onBackground,
                onBorder,
            }) {

    const fileInputRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [hoveredLabel, setHoveredLabel] = useState(null);
    const [prevFont, setPrevFont] = useState('Arial');  // Initialize with default font
    const [prevBG, setPrevBG] = useState('#FFFFFF');  // Initialize with default BGcolor

    const elements = [
        { icon: IoHandLeft, label: 'Pan', handler: 'select', size: 20 },
        { icon: FaLocationArrow, label: 'Select', handler: '', size: 20 },
        { icon: FaSquare, label: 'MIE', handler: 'MIE', size: 19 },
        { icon: FaCircle, label: 'KE', handler: 'KE', size: 20 },
        { icon: IoTriangle, label: 'AOP', handler: 'AOP', size: 22 },
        { icon: FaDiamond, label: 'AO', handler: 'AO', size: 19 },
        { icon: BsDashLg, label: 'Adjacent', handler: 'solidLink', size: 23 },
        { icon: RxBorderDashed, label: 'Non-Adjacent', handler: 'dashedLink', size: 23 },
        { icon: FaFont, label: 'Font', handler: 'font' },
        { icon: SlActionUndo, label: 'Undo', handler: 'undo', size: 18 },
        { icon: SlActionRedo, label: 'Redo', handler: 'redo', size: 18 },
        { icon: FaTrash, label: 'Clear', handler: 'trash', size: 18 }
    ];

    const handleStroke = (stroke) => onStroke(stroke);
    const handleStyle = (style) => onStyle(style);
    const handleBorder = (border) => onBorder(border);
    const handleBackground = (bg) => onBackground(bg);
    const handleFill = (fill) => onFill(fill);
    const handleRough = (rough) => onRough(rough);
    const handleFont = (font) => onFont(font);  // Font handling function

    const handleClick = (type) => {
        if (type === 'open') {
            fileInputRef.current.click(); 
            onSelect(type);
            setSelectedOption(type);
        } else {
            onSelect(type);
            setSelectedOption(type);
        }
    };

    const getIconStyle = (type) => selectedOption === type ? { color: 'gray', fontWeight: 'bold' } : {};

    // Reusable function to render hover label
    const renderHoverLabel = (label, translateX) => (
        hoveredLabel === label && (
            <Col style={{
                position: 'absolute',
                top: '-20px',
                whiteSpace: 'nowrap',
                fontFamily: 'Cursive',
                transform: `translateX(${translateX}px)`
            }}>
                {label}
            </Col>
        )
    );

    const header = {
        position: 'fixed', 
        padding: '15px',
        display: 'flex',
        bottom: 0,
        alignItems: 'center',
        left: '50%',
        transform: 'translateX(-50%)', 
        width: 'auto',
        zIndex: 1000,
        flexDirection: 'row',
        justifyContent: 'space-between',
    };

    const header__icons = {
        cursor: 'pointer',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        padding: '10px',
        borderRadius: '10px',
        boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.2)',
        color: 'black',
    };

    return (
        <>
        <Container style={header} className='header__responsive'>
            
            <Row style={{ ...header__icons, position: 'relative' }} className='menu__craft'>

                <Col 
                    onMouseEnter={() => setHoveredLabel('BG Color')}
                    onMouseLeave={() => setHoveredLabel(null)}
                >
                    <Background
                        style={getIconStyle('paperpalette')}
                        onChange={handleBackground}
                        prevState={prevBG}
                        setPrevState={setPrevBG}
                    />
                    {renderHoverLabel('BG Color', -15)}
                </Col>


                <Col 
                    onMouseEnter={() => setHoveredLabel('DesignPalette')}
                    onMouseLeave={() => setHoveredLabel(null)}
                >
                    <DesignPalette 
                        onFillChange={handleFill}
                        onStyleChange={handleStyle} 
                        onStrokeChange={handleStroke} 
                        onBorderChange={handleBorder} 
                        onRoughChange={handleRough}
                        style={getIconStyle('elementpalette')}
                    />
                    {renderHoverLabel('DesignPalette', -25)}
                </Col>

                {elements.map((element, index) => (
                    <React.Fragment key={index}>
                        <Col
                            onMouseEnter={() => setHoveredLabel(element.label)}
                            onMouseLeave={() => setHoveredLabel(null)}
                            >
                            {/* Handle Font Component rendering instead of using icon */}
                            {element.handler === 'font' ? (
                                <Fonts 
                                onChange={handleFont}  // Pass the onFontChange handler
                                style={getIconStyle('font')}
                                prevState={prevFont}
                                setPrevState={setPrevFont}
                                />
                            ) : (
                                <element.icon onClick={() => handleClick(element.handler)}
                                 style={getIconStyle(element.handler)} size={element.size} />
                            )}
                            {renderHoverLabel(element.label, -5)}
                        </Col>
                    </React.Fragment>
                ))}


                <Col 
                    onMouseEnter={() => setHoveredLabel('Menu')}
                    onMouseLeave={() => setHoveredLabel(null)}
                >
                    <Menu 
                        onOptionClick={handleClick}
                        style={getIconStyle('menupalette')}
                    />
                    {renderHoverLabel('Menu', -12)}
                </Col> 

            </Row>

            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={(e) => {
                    fileSelectionEvent(e);
                    e.target.value = '';
                }}
            />

        </Container>
        </>
    );
}

export default Header;
