import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFormPosition } from '../../../redux/slices/form/form';

const DraggableDiv = ({ children }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const divRef = useRef(null);

  const dispatch = useDispatch();
  const position = useSelector((state) => state.form.position);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    const rect = divRef.current.getBoundingClientRect();
    setOffset({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.clientX - offset.x;
    const y = e.clientY - offset.y;
    divRef.current.style.transform = `translate(${x - position.x}px, ${y - position.y}px)`;
  };

  const handleMouseUp = (e) => {
    if (isDragging) {
      const x = e.clientX - offset.x;
      const y = e.clientY - offset.y;
      dispatch(setFormPosition({ x, y }));
      divRef.current.style.transform = 'none';
      setIsDragging(false);
    }
  };

  const draggableDivStyle = {
    width: '40%',
    height: '80%',
    backgroundColor: 'white',
    border: '2px solid gray',
    borderRadius: '3px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    position: 'absolute',
    left: `${position.x}px`,
    top: `${position.y}px`,
    cursor: isDragging ? 'grabbing' : 'grab',
  };

  return (
    <div
      ref={divRef}
      style={draggableDivStyle}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      {children}
    </div>
  );
};

export default DraggableDiv;
