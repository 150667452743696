import { useState } from 'react';
import SearchBox from './SearchBox';
import { Button } from 'react-bootstrap';

function AOPSearch({ renderGraph }) {

  // Test URL - URLs will come from queries
  const test_url_1 = "https://np.test.knowledgepixels.com/RAcnSOfAIcap1GL4QwGljU1fC0Y2uJGvpyffpifT8b-UQ.jsonld";
  const test_url_2 = "https://np.test.knowledgepixels.com/RA44PWxKj60UDvoOwOt1JXTuh5v8V93mElVfroW_iUnYU.jsonld";

  const [url, setUrl] = useState(test_url_2);

  return (
    <>
      <Button variant="dark" onClick={()=>renderGraph(url)}>Render</Button>
      <SearchBox name="ID" />
      <SearchBox name="Label" />
      <SearchBox name="Authors" />
      <SearchBox name="Cell line" />
    </>
  );
}

export default AOPSearch;
