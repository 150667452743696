import './orcid-widget.css';
import React, { useEffect, useState } from 'react';

function ORCIDAuth() {
  const [userInfo, setUserInfo] = useState(null);  
  const [isAuthenticated, setIsAuthenticated] = useState(false);  
  const [loading, setLoading] = useState(true);


  const backend_host = process.env.REACT_APP_BACKEND_URL

  const backendLoginUrl = `${backend_host}/api/v1/login-orcid`;
  const backendUserInfoUrl = `${backend_host}/api/v1/user/info`;

  function login() {
    // Redirect the user to the backend login endpoint
    window.location.href = backendLoginUrl;
  }

  // Function to handle the user information and token after login
  function handleLoginCallback() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      
      localStorage.setItem('token', token);
      window.history.replaceState({}, document.title, "/");
      fetchUserInfo(token);

    } else {
      // If token exists in localStorage, fetch user info directly
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        fetchUserInfo(storedToken);
      } else {
        setLoading(false); 
      }
    }
  }

  // Function to fetch user info from backend
  function fetchUserInfo(token) {
    fetch(backendUserInfoUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`, 
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Failed to fetch user info");
        }
        return response.json();
      })
      .then(data => {
        setUserInfo(data);  
        setIsAuthenticated(true);
      })
      .catch(error => {
        console.error("Error fetching user info:", error);
        setIsAuthenticated(false);
      })
      .finally(() => {
        setLoading(false);  // Stop loading after fetch
      });
  }

  // Rerender with the page reload
  useEffect(() => {
    handleLoginCallback();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{margin: '0px', padding: '0px'}}>
      {!isAuthenticated ? (
        // login button if the user is not authenticated
        <div onClick={login} className='orcidAuthButton'>
          <table>
              <tr>
                  <td><img src="https://orcid.org/sites/default/files/images/orcid_24x24.png" 
                  alt="ORCID iD"/></td>
                  <td>&nbsp;Login</td>
              </tr>
          </table>
        </div>
      ) : (
        // Show the user info if the user is authenticated
        <div >
          {/* <img
            // src="https://orcid.org/sites/default/files/images/orcid_24x24.png" // Replace with actual user profile image URL
            alt="User Profile"
            style={{
              borderRadius: '50%',
              marginRight: '15px',
              width: '30px',
              height: '30px',
            }}
          /> */}
          <div style={{justifyItems: 'center', maxWidth: '300px', wordWrap: 'break-word' }}>
            <h2
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
                whiteSpace: 'normal',
                lineHeight: '1.2',
              }}
            >
              {userInfo.fullname}
            </h2>
            <a
              href={`https://orcid.org/${userInfo.orcid}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                fontSize: '10px',
                color: '#0077b5',
                textDecoration: 'none',
                display: 'block',
                marginTop: '2px',
              }}
            >
              {userInfo.orcid}
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default ORCIDAuth;
