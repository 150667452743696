
export default function ConverterFunction(data) {
    const baseIRI = process.env.REACT_APP_AOP_BASE_URI; // base uri for identifier
    const rdfData = [];

    function createRDFEntity(obj, parentKey = '') {
        const rdfEntity = {
            "@id": `${baseIRI}${parentKey}`
        };

        for (const key in obj) {
            if (key === 'objectID') continue; // Skip `objectID` in RDF entities

            const rdfKey = `${baseIRI}has${key}`;

            if (Array.isArray(obj[key])) {
                rdfEntity[rdfKey] = obj[key].map((item) => {
                    if (typeof item === 'object') {
                        const nestedEntity = createRDFEntity(item, key);
                        rdfData.push(nestedEntity);
                        return { "@id": `${baseIRI}${key}` };
                    } else {
                        return { "@value": item };
                    }
                });
            } else if (typeof obj[key] === 'object') {
                const nestedEntity = createRDFEntity(obj[key], key);
                rdfData.push(nestedEntity);
                rdfEntity[rdfKey] = [{ "@id": `${baseIRI}${key}` }];
            } else {
                rdfEntity[rdfKey] = [{ "@value": obj[key] }];
            }
        }

        return rdfEntity;
    }

    function processItem(item) {
        const rdfEntity = {
            "@id": `${baseIRI}KeyEvent`
        };

        for (const key in item) {
            if (key === 'objectID') {
                // Handle `objectID` if it's a nested object
                if (typeof item[key] === 'object') {
                    createRDFEntity(item[key], key);
                }
                continue;
            }

            if (typeof item[key] === 'object') {
                if (Array.isArray(item[key])) {
                    // Process arrays of objects
                    rdfEntity[`${baseIRI}has${key}`] = item[key].map((subItem) => {
                        if (typeof subItem === 'object') {
                            const nestedEntity = createRDFEntity(subItem, key);
                            rdfData.push(nestedEntity);
                            return { "@id": `${baseIRI}${key}` };
                        } else {
                            return { "@value": subItem };
                        }
                    });
                } else {
                    // Process nested objects
                    const nestedEntity = createRDFEntity(item[key], key);
                    rdfData.push(nestedEntity);
                    rdfEntity[`${baseIRI}has${key}`] = [{ "@id": `${baseIRI}${key}` }];
                }
            } else {
                // Handle primitive values
                rdfEntity[`${baseIRI}has${key}`] = [{ "@value": item[key] }];
            }
        }

        rdfData.push(rdfEntity);
    }

    data.forEach(processItem);

    return rdfData;
}
