import React from 'react'
import SearchBox from './SearchBox'
import { Container, Button, Row, Col } from 'react-bootstrap'

function KESearch({createMIE}) {

  return (
    <>
      <Container>
        <Row className="justify-content-between">
          <Col xs="auto">
            <Button variant="dark" onClick={()=>createMIE('MIE')}>MIE</Button>
          </Col>
          <Col xs="auto">
            <Button variant="dark" onClick={()=>createMIE('KE')}>KE</Button>
          </Col>
          <Col xs="auto">
            <Button variant="dark" onClick={()=>createMIE('AO')}>AO</Button>
          </Col>
        </Row>
      </Container>
      <SearchBox name="ID" />
      <SearchBox name="Label" />
      <SearchBox name="AOP" />
      <SearchBox name="Stressor" />
      <SearchBox name="cell type" />
   </>
  )
}

export default KESearch
